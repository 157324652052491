import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { HeaderDashboardComponent } from './components/header-dashboard/header-dashboard.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { ToastsComponent } from './components/toasts/toasts.component';
import { BranchLayoutComponent } from './layouts/branch-layout/branch-layout.component';
import { UserModalComponent } from './components/user-modal/user-modal.component';
import { ToastService } from './services/toast.service';
import { MovementModalComponent } from './components/movement-modal/movement-modal.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    SessionLayoutComponent,
    SideBarComponent,
    MenuItemComponent,
    HeaderDashboardComponent,
    DashboardLayoutComponent,
    ToastsComponent,
    BranchLayoutComponent,
    UserModalComponent,
    MovementModalComponent,
    SideMenuComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    SharedModule
  ],
  providers: [
    ScreenTrackingService,
    ToastService,
    UserTrackingService
  ],
  bootstrap: [AppComponent]
  
})
export class AppModule { }

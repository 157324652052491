import { Component } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { BRANCH_MENU } from '../../data/menu.data';
import { ActivatedRoute } from '@angular/router';
import { BranchService } from '../../services/branch.service';

@Component({
  selector: 'app-branch-layout',
  templateUrl: './branch-layout.component.html',
  styleUrl: './branch-layout.component.scss'
})
export class BranchLayoutComponent {

  public outerHeight: number = document.documentElement.clientHeight;

  public menu: Array<Menu> = BRANCH_MENU;

  constructor(
    private activatedRoute: ActivatedRoute,
    public branchService: BranchService
  ) {
    this.activatedRoute.params.subscribe((params) => {

      // console.log(params);

      this.branchService.get(params['idBranch']);
    });
  }

}

<div class="container-fluid bg-primary-light py-2">
  <div class="row">
    <div class="col-6">

      <button
        class="btn pt-2 pb-0 d-block d-md-none"
        (click)="slideMenuService.showed = !slideMenuService.showed">
        <i class="material-icons">menu</i>
      </button>

      @if(router.url.includes('branches')) {
        <div ngbDropdown class="d-inline-block d-none d-md-block">
          <button type="button" class="btn btn-primary" id="dropdownBasic2" ngbDropdownToggle>
            {{ branchService.currentBranch?.name }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <a
              [routerLink]="['/dashboard']" 
              ngbDropdownItem 
              class="d-flex align-items-center">
              <i class="material-icons me-1">business</i>
              Todas las sucursales
            </a>
            <div class="dropdown-divider"></div>
            @for(branch of branchService.branches; track branch.idBranch) {
              <a
                [routerLink]="['/branches', branch.idBranch]" 
                ngbDropdownItem 
                class="d-flex align-items-center">
                {{ branch.name }}
              </a>
            }
          </div>
        </div>
      }
    </div>

    <div class="col-6 text-end">

      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn text-white" id="dropdownBasic1" ngbDropdownToggle>
          {{ authService.currentUser?.getFullName() }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a
            [routerLink]="['/dashboard']" 
            ngbDropdownItem 
            class="d-flex align-items-center">
            <i class="material-icons me-1">business</i>
            Sucursales
          </a>
          <div class="dropdown-divider"></div>
          <button 
            ngbDropdownItem 
            class="d-flex align-items-center text-danger"
            (click)="authService.signOut()">
            <i class="material-icons me-1">logout</i>
            Cerrar sesión
          </button>
        </div>
      </div>

    </div>

    @if(router.url.includes('branches')) {
      <div class="col-12 d-block d-md-none text-end">
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-primary" id="dropdownBasic2" ngbDropdownToggle>
            {{ branchService.currentBranch?.name }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <a
              [routerLink]="['/dashboard']" 
              ngbDropdownItem 
              class="d-flex align-items-center">
              <i class="material-icons me-1">business</i>
              Todas las sucursales
            </a>
            <div class="dropdown-divider"></div>
            @for(branch of branchService.branches; track branch.idBranch) {
              <a
                [routerLink]="['/branches', branch.idBranch]" 
                ngbDropdownItem 
                class="d-flex align-items-center">
                {{ branch.name }}
              </a>
            }
          </div>
        </div>
      </div>
    }

  </div>
</div>
import { Menu } from '../models/menu.model';

export const MENU: Array<Menu> = [
  {
    icon: 'dashboard',
    route: '/dashboard',
    title: 'Dashboard',
  },
  {
    icon: 'group',
    route: 'users',
    title: 'Usuarios',
  },
  // {
  //   icon: 'business',
  //   route: '/companies',
  //   title: 'Compañias',
  // },
];

export const BRANCH_MENU: Array<Menu> = [
  {
    icon: 'dashboard',
    route: '',
    title: 'Dashboard',
  },
  {
    icon: 'directions_car',
    route: 'orders',
    title: 'Ordenes',
  },
  {
    icon: 'receipt_long',
    route: 'quotations',
    title: 'Cotizaciones',
  },
  {
    icon: 'engineering',
    route: 'board',
    title: 'Seguimiento',
  },
  {
    icon: 'people',
    route: 'clients',
    title: 'Clientes',
  },
  {
    icon: 'storage',
    route: 'storage',
    title: 'Inventario',
  },
  {
    icon: 'warehouse',
    route: 'warehouse',
    title: 'Almacén',
  },
  {
    icon: 'forklift',
    route: 'suppliers',
    title: 'Proveedores',
  },
  {
    icon: 'security',
    title: 'Seguridad',
    children: [
      {
        route: 'security/modules',
        title: 'Módulos',
      },
      {
        route: 'security/roles',
        title: 'Roles',
      },
      {
        route: 'security/staff',
        title: 'Usuarios',
      },
    ]
  },
  {
    icon: 'category',
    title: 'Catálogos',
    children: [
      {
        route: 'catalogues/indicators',
        title: 'Indicadores',
      },
      {
        route: 'catalogues/stock',
        title: 'Inventario',
      },
      {
        route: 'catalogues/platforms',
        title: 'Plataformas',
      },
    ]
  },
  {
    icon: 'settings',
    title: 'Configuraciones',
    children: [
      {
        route: 'checklists',
        title: 'Datos de la sucursal',
      },
      {
        route: 'status-workflows',
        title: 'Workflow',
      }
    ]
  },
];

import { Component } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { BRANCH_MENU } from '../../data/menu.data';
import { ActivatedRoute } from '@angular/router';
import { BranchService } from '../../services/branch.service';
import { SlideMenuService } from '../../services/slide-menu.service';

@Component({
  selector: 'app-branch-layout',
  templateUrl: './branch-layout.component.html',
  styleUrl: './branch-layout.component.scss'
})
export class BranchLayoutComponent {

  public outerHeight: number = document.documentElement.clientHeight;

  public menu: Array<Menu> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public branchService: BranchService,
    public slideMenuService: SlideMenuService
  ) {
    this.activatedRoute.params.subscribe((params) => {

      // console.log(params);

      this.branchService.get(params['idBranch']);
    });

    this.checkRoleMenus();
  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗███╗   ███╗███████╗███╗   ██╗██╗   ██╗██╗    ██╗██╗████████╗██╗  ██╗██████╗  █████╗ ██████╗  █████╗ ███╗   ███╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝████╗ ████║██╔════╝████╗  ██║██║   ██║██║    ██║██║╚══██╔══╝██║  ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗████╗ ████║██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗  ██╔████╔██║█████╗  ██╔██╗ ██║██║   ██║██║ █╗ ██║██║   ██║   ███████║██████╔╝███████║██████╔╝███████║██╔████╔██║███████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║   ██║██║███╗██║██║   ██║   ██╔══██║██╔═══╝ ██╔══██║██╔══██╗██╔══██║██║╚██╔╝██║╚════██║
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╔╝╚███╔███╔╝██║   ██║   ██║  ██║██║     ██║  ██║██║  ██║██║  ██║██║ ╚═╝ ██║███████║
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝  ╚══╝╚══╝ ╚═╝   ╚═╝   ╚═╝  ╚═╝╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝

  */
   createMenuWithParams() {
    this.activatedRoute.firstChild?.params.subscribe((params) => {
      console.log(params);

      const idBranch = params['idBranch'];

      console.log(idBranch);

      console.log(BRANCH_MENU);
      

      // let menu = BRANCH_MENU.map((menuItem) => menuItem);
      let menu = this.menu.map(
        (menuItem) => (
          { 
            ...menuItem, 
            children: menuItem.children 
              ? menuItem.children.map(
                (child) => (
                  { 
                    ...child 
                  }
                )
              ) 
              : undefined 
          }
        )
      );
      

      menu.forEach((menuItem) => {

        if(menuItem.route) {
  
          menuItem.route = menuItem.route.replace(':idBranch', idBranch);
  
        } else {
  
          menuItem.children?.forEach((submenuItem) => {
  
            if(submenuItem.route) {
  
              submenuItem.route = submenuItem.route.replace(':idBranch', idBranch);
  
            }
  
          });
  
        }
  
      });

      this.menu = menu;
      
      
    });
  }

  /*
   ██████╗██╗  ██╗███████╗ ██████╗██╗  ██╗██████╗  ██████╗ ██╗     ███████╗███╗   ███╗███████╗███╗   ██╗██╗   ██╗███████╗
  ██╔════╝██║  ██║██╔════╝██╔════╝██║ ██╔╝██╔══██╗██╔═══██╗██║     ██╔════╝████╗ ████║██╔════╝████╗  ██║██║   ██║██╔════╝
  ██║     ███████║█████╗  ██║     █████╔╝ ██████╔╝██║   ██║██║     █████╗  ██╔████╔██║█████╗  ██╔██╗ ██║██║   ██║███████╗
  ██║     ██╔══██║██╔══╝  ██║     ██╔═██╗ ██╔══██╗██║   ██║██║     ██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║   ██║╚════██║
  ╚██████╗██║  ██║███████╗╚██████╗██║  ██╗██║  ██║╚██████╔╝███████╗███████╗██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╔╝███████║
   ╚═════╝╚═╝  ╚═╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝

  */
  checkRoleMenus() {
    if(this.menu.length === 0) {

      let menus = BRANCH_MENU;

      for(let menu of menus) {

        // if(menu.rolesCanAccess) {
        //   if(menu.rolesCanAccess.includes(this.authService.currentUser!.profile)) {
        //     this.menu.push(menu);
        //   }
        // } else if(menu.children) {
          
        //   menu.children = menu.children.filter(
        //     (child) => child.rolesCanAccess!.includes(this.authService.currentUser!.profile)
        //   );

        //   this.menu.push(menu);

        // } else {
        //   this.menu.push(menu);
        // }

        this.menu.push(menu);

      }

      this.createMenuWithParams();

    }
  }

}

import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BranchService } from '../../services/branch.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrl: './header-dashboard.component.scss'
})
export class HeaderDashboardComponent {

  constructor(
    public authService: AuthService,
    public branchService: BranchService,
    public router: Router
  ) {

  }

}

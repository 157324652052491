<main class="container-fluid">
  <div class="row">
    <div
      class="d-none d-md-block col-12 col-md-3 col-lg-3 col-xl-2 p-0 bg-primary"
      [ngStyle]="{ 'height.px': outerHeight }"
    >
      <app-side-bar
        [menuData]="menu"></app-side-bar>
    </div>

    <div
      class="col-12 col-md-9 col-lg-9 col-xl-10 p-0"
      [ngStyle]="{ 'max-height.px': outerHeight }"
      style="overflow-y: auto"
    >
      <div class="container-fluid h-100 bg-secondary">
        <div class="row bg-secondary">
          <div class="col-12 p-0">
            <app-header-dashboard/>
          </div>

          <div class="col-12 bg-secondary">
            <router-outlet></router-outlet>
          </div>

          <!-- TODO: add footer when needed -->
        </div>
      </div>
    </div>
  </div>
</main>

@if (slideMenuService.showed) {
  <app-side-menu  
    [menuData]="menu">
  </app-side-menu>
}